import { ReactNode } from "react";
import dayjs from "dayjs";
import type {
    ILeadFilter,
    DatetimeFilter,
} from "@api/leads";
import type {
    ICardDataRowSelector,
} from "~/model/card";
import { pullValue } from "./obj";
import { internalDateFormat, iso8601offset } from "~/meta";
import { dateToInternal } from "./transform";

import type { AvaAppEntity } from "~/model/leads";

export const parseAttribute = (value?: string | null): ObjectPrimitive | null => {
    if (!value) return null;
    try {
        return JSON.parse(value);
    } catch (error) {
        return null;
    }
};

// export const flattenAttributes = (attributes: ConsumerAttributes): ComposedObject => {
//     return attributes.reduce((acc, attr) => {
//         const type = attr.attributeType;
//         if (!type || typeof type !== "object" || !type.id) return acc;

//         acc[type.id as string] = parseAttribute(attr.value?.toString());

//         return acc;
//     }, {} as ComposedObject);
// };

interface IConsumerRowValues extends ICardDataRowSelector {
    value: ReactNode | string | null;
}

export const mapValueBySelector = <T extends ComposedObject>(obj: T, selectors: ICardDataRowSelector[]): IConsumerRowValues[] => {
    return selectors.map((selector) => {
        const value = pullValue<string>(selector.property, obj, selector.join);
        const formatted = selector.format
            ? selector.format(value)
            : value;
        return {
            ...selector,
            value: formatted,
        };
    });
};

export interface ILeadsFilterParams {
    creditVerified: string;
    dateFrom: string;
    dateTo: string;
    search: string;
    product: string;
}
export type LeadsFilterInputHandler = Required<{
    [K in keyof ILeadsFilterParams]: (val: ILeadsFilterParams[K]) => void;
}>;
type DateFilterKeys = keyof DatetimeFilter;

export const getLeadFilterInput = ({
    creditVerified,
    dateFrom,
    dateTo,
    search,
    product,
}: ILeadsFilterParams): ILeadFilter => {
    const dateProcessor: [string | undefined, DateFilterKeys][] = [
        [dateFrom, "greaterThanOrEqualTo"],
        [dateTo, "lessThan"],
    ];
    const dates = dateProcessor.reduce<DatetimeFilter>((acc, [rawDt, key]) => {
        const dt = dayjs(rawDt, [iso8601offset, internalDateFormat], true);
        if (dt.isValid()) {
            if (key === "lessThan") {
                acc[key] = dt.add(1, "day").toISOString();
            } else {
                acc[key] = dt.toISOString();
            }
        }
        return acc;
    }, {});
    const verified = creditVerified === "true";

    return Object.assign(
        {},
        Object.keys(dates).length > 0 ? { dates } : {},
        creditVerified ? { creditVerified: verified } : {},
        search ? { search } : {},
        product ? { product } : {},
    );
};

type ValidateLeadFilterObj = Pick<ILeadsFilterParams, "dateFrom" | "dateTo">;
export const validateLeadFilter = ({ dateFrom, dateTo, ...rest }: ILeadsFilterParams): ILeadsFilterParams => {
    const dateValues = Object.entries({ dateFrom, dateTo })
        .reduce((acc, [key, val]) => {
            acc[key as keyof ValidateLeadFilterObj] = dateToInternal(val);
            return acc;
        }, {} as ValidateLeadFilterObj);

    return {
        ...rest,
        ...dateValues,
    };
};

export const getScoreRange = (avaApp?: AvaAppEntity): string => {
    const creditBureau = avaApp?.creditBureaus.nodes[0];
    if (!creditBureau) return "";

    const isExpired = creditBureau.expired;
    return isExpired && "Expired" || avaApp.creditRange || "";
};

export const bureauExpired = (createdDate?: Date | string): boolean => {
    const created = dayjs(createdDate).utc().startOf("day");
    const now = dayjs().utc().startOf("day");
    const limit = now.subtract(35, "day");
    return created.diff(limit) < 0;
};
