import React from "react";
import dayjs from "dayjs";

import { ITableColumn } from "~/components/Table";

import TradeLogo from "~/assets/vectors/trade-icon.svg";
import CreditLogo from "~/assets/vectors/credit-icon.svg";

import {
    transformToPhone,
    timestampToDateTime,
    transformToCurrency,
    toProperCase,
} from "@utils/transform";

import { getScoreRange } from "@utils/leads";

import type {
    LeadsListCreditReportMeta,
    LeadsListTradeToolMeta,
} from "~/model/leads";

import type { ActiveALSProduct } from "~/model/products";

import Tooltip from "@material-ui/core/Tooltip";
import { ActiveProduct, Products } from "~/components/Products";

type ProductLeadColumns = Record<ActiveALSProduct, ITableColumn[]>;

const iconStyle = {
    height: 24,
    width: 24,
};

const commonLeadColumns: ITableColumn[] = [
    {
        label: "Full Name",
        property: ["firstName", "lastName"],
        width: 300,
        cell: (props): string => toProperCase(props.join(" ")),
    },
    {
        label: "Phone",
        property: "phoneNumber",
        width: 225,
        cell: (props): string => transformToPhone(props.join(""), true),
    },
    {
        label: "Date Applied",
        property: "createdAt",
        width: 260,
        cell: (props): string => timestampToDateTime(dayjs(props[0] as string)),
    },
    {
        label: "Email",
        property: "emailAddress",
        width: 350,
    },
    {
        label: "City",
        property: "city",
        cell: (props): string => props && toProperCase(props[0]?.toString()),
        width: 180,
    },
];


interface LeadJourney {
    stage: string;
    product: {
        id: string;
        name: string;
    }
}

const extractProduct = (leadJourneys: LeadJourney[]) => (product: string, completed: string) => {
    const arr = leadJourneys?.filter((j) => j?.product?.name === product) || [];
    if (!arr.length) {
        return null;
    }

    const active = !!arr.find((j) => j?.stage === completed);

    return { product, active };
};

const extractProducts = ({ nodes: leadJourneys }: { nodes: LeadJourney[] }): ActiveProduct[] => {
    const extract = extractProduct(leadJourneys);
    const ID = extract("ID_TOOL", "ID_COMPLETE");
    const CREDIT = extract("CREDIT_TOOL", "CREDIT_SCORE");
    const TRADE = extract("TRADE_TOOL", "TRADE");

    return [
        CREDIT,
        TRADE,
        ID,
    ].filter(Boolean) as ActiveProduct[];
};

export const leadColumns: ProductLeadColumns = {
    GLOBAL: [
        ...commonLeadColumns,
        {
            label: "Products",
            property: "leadJourneys",
            align: "left",
            cell(props) {
                const products = extractProducts(props[0]);

                return (
                    <div
                        style={{
                            display: "flex",
                            flexFlow: "row nowrap",
                            justifyContent: "flex-start",
                            alignItems: "center",
                        }}
                    >
                        <Products products={products} />
                    </div>
                );
            },
        },
    ],
    CREDIT_TOOL: [
        ...commonLeadColumns,
        {
            label: "Credit Range",
            property: "avaApp",
            align: "left",
            width: 170,
            cell(props) {
                const meta = props[0] as LeadsListCreditReportMeta | undefined;
                const creditRange = getScoreRange(meta);

                return (
                    <div
                        style={{
                            display: "flex",
                            flexFlow: "row nowrap",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        {`${creditRange} `}
                    </div>
                );
            },
        },
    ],
    TRADE_TOOL: [
        ...commonLeadColumns,
        {
            label: "Trade Value",
            property: "tradeApps",
            align: "left",
            width: 150,
            cell(props) {
                const tradeApp = props[0] as LeadsListTradeToolMeta | undefined;
                const tradeInValue = tradeApp && tradeApp.nodes[0]?.tradeInValue || "";
                return tradeInValue && transformToCurrency(tradeInValue) || "";
            },
        },
    ],
    LENDER_TOOL: [],
    TRADE_PRO: [],
};