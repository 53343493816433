import React, { useEffect, useMemo, useRef } from "react";
import { ApolloError } from "@apollo/client";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import { Table, ITableRow, ITableErrorProps, getEntityRow } from "~/components/Table";
import { Pager } from "~/components/Pager";
import { prefetch } from "@utils/prefetch";

import { leadColumns } from "./columns";

import type { LeadsListQueryReturn, LeadsListEntity } from "~/model/leads";
import type { PageInfo } from "@graphql/types";
import type { IComponentNavigateFn } from "@utils/navigate";
import type { ActiveALSProduct } from "~/model/products";

const useStyles = makeStyles((theme) => ({
    tableContainer: {
        display: "flex",
        flexFlow: "column nowrap",
        width: "100%",
        height: "100%",
        overflow: "auto",
    },
    table: {
        minHeight: 350,
        height: "100%",
        userSelect: "none",
    },
    pager: {
        maxWidth: 250,
        marginLeft: "auto",
        padding: 10,
    },
}));

const tableError: ITableErrorProps = {
    title: "Oops... something went wrong.",
    message: "Please, refresh browser or contact your admin.",
};

interface IProcessedQuery {
    rows: ITableRow[];
    error?: ITableErrorProps;
}

interface ILeadsListProps {
    leads: LeadsListQueryReturn;
    selectedProduct: ActiveALSProduct;
    nextKey: string;
    page?: PageInfo;
    loading: boolean;
    queryError?: ApolloError;
    navigate: IComponentNavigateFn;
}

export const LeadsList: React.FC<ILeadsListProps> = ({
    leads,
    selectedProduct,
    nextKey,
    page,
    loading,
    queryError,
    navigate,
}) => {
    const styles = useStyles();
    const tableRef = useRef<HTMLDivElement>(null);

    const leadListColumns = useMemo(() => (
        leadColumns[selectedProduct] || leadColumns["GLOBAL"]
    ),[selectedProduct]);

    const {
        error,
        rows,
    } = useMemo<IProcessedQuery>(() => {
        if (!loading) {
            if (queryError) {
                return {
                    rows: [],
                    error: tableError,
                };
            }

            const getLeadPath = (id: string) => (
                `/lead/${id}`
            );

            const handleSelect = (id: string, title: string, cognitoName: string) => () => {
                if (tableRef.current) {
                    history.replaceState({
                        ...history.state,
                        scrollPos: tableRef.current?.scrollTop,
                    }, "", null);
                }
                navigate(getLeadPath(id), {
                    savePath: true,
                    state: {
                        title,
                        skipRouteChangeEvent: true,
                        cognitoName,
                    },
                });
            };

            let prefetched = false;

            const getPrefetcher = (id: string) => () => (
                !prefetched && (prefetched = true) &&
                prefetch(getLeadPath(id))
            );

            const newRows: ITableRow[] = leads?.edges.map(({ node }, i) => ({
                id: node.id,
                select: handleSelect(
                    node.id,
                    [node.firstName, node.lastName].filter(Boolean).join(" "),
                    node.company?.groupByPrimaryGroup?.cognitoName || "",
                ),
                prefetch: getPrefetcher(node.id),
                values: getEntityRow<LeadsListEntity>(
                    leadListColumns,
                    node,
                    i,
                ),
                rowStyles: {
                    cells: !node?.read && { fontWeight: 800 },
                },
            })) || [];

            return {
                rows: newRows,
            };
        }

        return {
            rows: [],
        };
    }, [loading, queryError, leads?.edges, navigate, leadListColumns]);

    useEffect(() => {
        if (history.state?.["scrollPos"] && tableRef.current) {
            tableRef.current.scrollTop = history.state["scrollPos"];
            delete history.state["scrollPos"];
            history.replaceState(history.state, "", null);
        }
    }, [rows]);

    const hasPrev = !!nextKey;
    const endCursor = page?.hasNextPage && page?.endCursor || undefined;

    return (
        <Box className={styles.tableContainer}>
            <Box className={styles.table}>
                <Table
                    containerRef={tableRef}
                    columns={leadListColumns}
                    rows={rows}
                    loading={loading}
                    error={error}
                    emptyMessage={"You don't have any leads yet"}
                />
            </Box>
            {(hasPrev || !!endCursor) && (
                <Box className={styles.pager}>
                    <Pager
                        nextPrev
                        hasPrev={hasPrev}
                        nextKey={endCursor}
                    />
                </Box>
            )}
        </Box>
    );
};