import React, { useState, useLayoutEffect } from "react";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    KeyboardDatePickerProps,
} from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";
import DayjsUtils from "@date-io/dayjs";
import clsx from "clsx";
import { dateFormat } from "~/meta";

const useStyles = makeStyles((theme) => ({
    inputControl: {
        padding: "15px 15px",
        paddingRight: 0,
        width: "100%",
    },
    dateRoot: {
        margin: 5,
        backgroundColor: theme.palette.background.paper,
    },
    dateControl: {
        textAlign: "center",
        width: "100%",
    },
}));

export interface IDatePickerProps extends Omit<KeyboardDatePickerProps, "value"> {
    value?: string | Date;
}

export const DatePicker: React.FC<IDatePickerProps> = ({
    className,
    InputProps,
    format,
    value,
    onChange,
    ...props
}) => {
    const [curValue, setValue] = useState(value);
    const styles = useStyles();

    useLayoutEffect(() => {
        setValue(value);
    }, [value]);

    const handleChange: React.ComponentProps<typeof KeyboardDatePicker>["onChange"] = (
        dt,
        newVal,
    ) => {
        setValue(dt?.toDate() || undefined);
        onChange(dt, newVal);
    };

    const handleFocus = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        e.currentTarget.selectionStart = 0;
        e.currentTarget.selectionEnd = e.currentTarget.value.length;
    };
    const handleRef = (node: HTMLDivElement | null) => {
        if (node) {
            node.querySelectorAll("button").forEach((el) => {
                el.tabIndex = -1;
            });
        }
    };

    return (
        <MuiPickersUtilsProvider utils={DayjsUtils}>
            <div ref={handleRef}>
                <KeyboardDatePicker
                    variant="inline"
                    inputVariant="outlined"
                    helperText=""
                    InputProps={{
                        // inputRef: handleRef,
                        color: "secondary",
                        ...InputProps,
                        classes: {
                            ...(InputProps?.classes || {}),
                            input: clsx(styles.inputControl, InputProps?.classes?.input),
                            root: clsx(styles.dateRoot, InputProps?.classes?.root),
                        },
                    }}
                    disableFuture
                    className={clsx(styles.dateControl, className)}
                    format={format || dateFormat}
                    autoOk
                    onFocus={handleFocus}
                    value={curValue || null}
                    onChange={handleChange}
                    {...props}
                />
            </div>
        </MuiPickersUtilsProvider>
    );
};