import React, {
    useState,
    useMemo,
    useCallback,
} from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import ResetIcon from "@material-ui/icons/Close";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import clsx from "clsx";
import dayjs from "dayjs";
import yn from "yn";

import { PrimaryButton } from "~/components/Button";
import { Input } from "~/components/Input";
import { DatePicker } from "~/components/DatePicker";

import { mp } from "@utils/mixpanel";
import { stringifyQuery } from "@utils/url";
import { dateToInternal } from "@utils/transform";
import {
    ILeadsFilterParams,
    LeadsFilterInputHandler,
} from "@utils/leads";

import type { INavigateFn } from "@utils/navigate";

const Label = withStyles((theme) => ({
    label: theme.typography.inputLabel,
}))(FormControlLabel);
const SearchBox = withStyles((theme) => ({
    root: {
        margin: 5,
        marginTop: 6,
        color: theme.palette.background.paper,
    },
}))(Input);
const DateFilterComponent: typeof DatePicker = (props) => {
    return (
        <DatePicker
            {...props}
            style={{
                width: 250,
                marginRight: 15,
            }}
        />
    );
};

const useStyles = makeStyles((theme) => ({
    controls: {
        height: 65,
        display: "flex",
        flexFlow: "row nowrap",
        justifyContent: "space-between",
        alignItems: "flex-start",
        "& > *": {
            display: "flex",
            flexFlow: "row nowrap",
        },
        padding: "0 10px",
    },
    inputControl: {
        padding: "15px 15px",
        paddingRight: 0,
        width: "100%",
    },
    resetAdornment: {
        visibility: "hidden",
        "&.show": {
            visibility: "visible",
        },
    },
    dateLabel: {
        ...theme.typography.inputLabel,
        marginRight: 10,
    },
    dateRoot: {
        margin: 5,
        backgroundColor: theme.palette.background.paper,
    },
    dateAdornment: {
        "& > button": {
            padding: 5,
        },
    },
    searchRoot: {
        backgroundColor: theme.palette.background.paper,
    },
    searchLabel: {
        color: `${theme.palette.text.primary} !important`,
    },
    searchControl: {
        width: 248,
    },
    dateControl: {
        textAlign: "center",
        width: 190,
        marginRight: 15,
    },
}));

interface IFiltersProps {
    curFilter: ILeadsFilterParams;
    filterRef: React.MutableRefObject<ILeadsFilterParams>;
    navigate: INavigateFn;
}

export const Filters: React.FC<IFiltersProps> = ({
    curFilter,
    filterRef,
    navigate,
}) => {
    const styles = useStyles();

    const [filterValues, setFilterValues] = useState(curFilter);

    const handleFilterUpdate = useCallback(() => {
        setFilterValues((curVal) => {
            const queryString = stringifyQuery(
                Object.entries(curVal).reduce((acc, [key, val]) => (
                    Object.assign(acc, {
                        [key]: (
                            key.startsWith("date") && val
                                ? dayjs(val).format()
                                : val
                        ),
                    })
                ), {}),
                true,
                true,
            );
            navigate(`?${queryString}`, {
                state: { skipRouteChangeEvent: true },
            });

            mp.fireEvent({
                event: "filteredLeads",
                context: {
                    searchString: curVal.search,
                    dateRange: (!curVal.dateFrom && !curVal.dateTo)
                        ? "N/A"
                        : `${curVal.dateFrom || "N/A"} - ${curVal.dateTo || " N/A"}`,
                    showCreditVerified: yn(
                        curVal.creditVerified,
                        { default: false },
                    ),
                },
            });
            return curVal;
        });
    }, [navigate]);

    const updateFilterValue = useMemo(() => {
        const keys: (keyof ILeadsFilterParams)[] = [
            "creditVerified",
            "dateFrom",
            "dateTo",
            "search",
        ];
        const skipKeys: (keyof ILeadsFilterParams)[] = [
            "creditVerified",
        ];
        const handler = (
            <K extends keyof LeadsFilterInputHandler>
            (field: K): (
                (val: ILeadsFilterParams[K]) => void
            ) => (val) => {
                setFilterValues((curVal) => {
                    if (
                        val.length === 0
                        && curFilter[field].length > 0
                        && !skipKeys.includes(field)
                    ) {
                        requestAnimationFrame(() => {
                            handleFilterUpdate();
                        });
                    }
                    const newFilters = {
                        ...curVal,
                        [field]: val,
                    };
                    filterRef.current = newFilters;
                    return newFilters;
                });
            }
        );

        return keys.reduce((acc, key) => {
            acc[key] = handler(key);
            return acc;
        }, {} as LeadsFilterInputHandler);

    }, [curFilter, filterRef, handleFilterUpdate]);

    return (
        <form
            noValidate
            autoComplete="off"
            onSubmit={(e) => e.preventDefault()}
            className={styles.controls}
        >
            <Box>
                <SearchBox
                    id="leads-list-search"
                    inputLabel="Search"
                    variant="outlined"

                    InputLabelProps={{
                        className: styles.searchLabel,
                        classes: {
                            focused: styles.searchLabel,
                        },
                    }}
                    InputProps={{
                        color: "secondary",
                        classes: {
                            input: styles.inputControl,
                            root: styles.searchRoot,
                        },
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <IconButton
                                className={clsx(
                                    styles.resetAdornment,
                                    filterValues.search.length > 0 && "show",
                                )}
                                style={{ padding: 5 }}
                                onClick={() => {
                                    updateFilterValue.search("");
                                }}
                            >
                                <ResetIcon />
                            </IconButton>
                        ),
                    }}

                    value={filterValues.search}
                    onChange={(e) => {
                        updateFilterValue.search(e.currentTarget.value);
                    }}
                />
            </Box>
            <Box>
                <Box mr={1} display="flex" alignItems="center" flexDirection="row">
                    <label className={styles.dateLabel}>Date:</label>
                    <DateFilterComponent
                        value={filterValues.dateFrom}
                        onChange={(_, value) => {
                            updateFilterValue.dateFrom(dateToInternal(value || ""));
                        }}
                        InputProps={{
                            startAdornment: (
                                <IconButton
                                    className={clsx(
                                        styles.resetAdornment,
                                        filterValues.dateFrom.length > 0 && "show",
                                    )}
                                    style={{ order: 1, padding: 5 }}
                                    onClick={() => {
                                        updateFilterValue.dateFrom("");
                                    }}
                                >
                                    <ResetIcon />
                                </IconButton>
                            ),
                        }}
                        InputAdornmentProps={{
                            position: "end",
                            style: {
                                order: 2,
                                marginRight: 0,
                                marginLeft: 0,
                                padding: 5,
                            },
                            classes: {
                                positionEnd: styles.dateAdornment,
                            },
                        }}
                    />
                    <DateFilterComponent
                        value={filterValues.dateTo}
                        onChange={(_, value) => {
                            updateFilterValue.dateTo(dateToInternal(value || ""));
                        }}
                        InputProps={{
                            startAdornment: (
                                <IconButton
                                    className={clsx(
                                        styles.resetAdornment,
                                        filterValues.dateTo.length > 0 && "show",
                                    )}
                                    style={{ order: 1, padding: 5 }}
                                    onClick={() => {
                                        updateFilterValue.dateTo("");
                                    }}
                                >
                                    <ResetIcon />
                                </IconButton>
                            ),
                        }}
                        InputAdornmentProps={{
                            position: "end",
                            style: {
                                order: 2,
                                marginRight: 0,
                                marginLeft: 0,
                                padding: 5,
                            },
                            classes: {
                                positionEnd: styles.dateAdornment,
                            },
                        }}
                    />
                    <PrimaryButton
                        type="submit"
                        onClick={() => handleFilterUpdate()}
                    >
                        {"Apply"}
                    </PrimaryButton>
                </Box>

                <Label
                    style={{
                        marginRight: 0,
                    }}
                    control={(
                        <Switch
                            checked={filterValues.creditVerified === "true"}
                            onChange={(e) => {
                                const newVal = e.currentTarget.checked ? "true" : "";
                                updateFilterValue.creditVerified(newVal);
                                handleFilterUpdate();
                            }}
                        />
                    )}
                    labelPlacement="start"
                    label="Credit Verified"
                />
            </Box>
        </form>
    );
};