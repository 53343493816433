import React, {
    Fragment,
    useEffect,
    useMemo,
    useRef,
    useLayoutEffect,
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import isNil from "lodash/isNil";

import { ViewComponent, createPrivateView } from "~/model/view/private";
import { TABLE_LIMIT } from "~/model/leads";
import { useAuth } from "@api/auth";
import { useLeadsList, useLeadProducts } from "@api/leads";

import CompanyPanel from "~/components/CompanyPanel";
import { captureException } from "@utils/sentry";
import { parseQuery, stringifyQuery } from "@utils/url";
import { getLeadFilterInput, ILeadsFilterParams } from "@utils/leads";
import { mp } from "@utils/mixpanel";

import { LeadsList } from "./_lib/LeadsList";
import { Filters } from "./_lib/Filters";
import { ProductFilter } from "./_lib/ProductFilter";
import { ExportCsvButton } from "./_lib/ExportCsvButton";
import { ActiveALSProduct } from "~/model/products";
import ProductCta from "~/components/ProductCta";


const useStyles = makeStyles((theme) => ({
    contentContainer: {
        display: "flex",
        flexFlow: "row nowrap",
        height: "100%",
        overflow: "hidden",
    },
    secondaryContent: {
        height: "100%",
        overflowX: "auto",
    },
    companyPanel: {
        borderTop: `1px solid ${theme.palette.borderInternal.main}`,
    },
    filters: {
        padding: "12px 24px",
    },
    exportCsvButton: {
        alignSelf: "center",
    },
}));

export const Leads: ViewComponent = ({ location, navigate }) => {
    const styles = useStyles();
    const {
        selectedCompany,
        changeCompany,
        user,
    } = useAuth();

    const curCompany = useRef(selectedCompany?.id);
    useLayoutEffect(() => {
        if (curCompany.current !== selectedCompany?.id) {
            const query = stringifyQuery({
                ...parseQuery(location?.search || ""),
                next: "",
            }, true);
            curCompany.current = selectedCompany?.id;
            navigate(`?${query}`, {
                state: { skipRouteChangeEvent: true },
            });
            mp.register({ dealerId: selectedCompany?.id });
        }
    }, [selectedCompany?.id, location?.search, navigate]);

    const curQueryParams = useMemo(() => {
        const {
            next = "",
            creditVerified = "",
            dateFrom = "",
            dateTo = "",
            search = "",
            product = "",
        } = parseQuery(location?.search || "") as Record<string, string>;

        return {
            next,
            creditVerified,
            dateFrom,
            dateTo,
            search,
            product,
        };
    }, [location?.search]);

    const nextKey = curQueryParams.next;

    const curFilter = useMemo(() => (
        getLeadFilterInput(curQueryParams)
    ), [curQueryParams]);

    const filterRef = useRef<ILeadsFilterParams>(curQueryParams);

    const {
        loading: isLeadsLoading,
        leads,
        page,
        error: queryError,
    } = useLeadsList(TABLE_LIMIT, curFilter, nextKey);

    const {
        productsIndex,
        productList,
        loading: isProductsLoading,
    } = useLeadProducts();

    useEffect(() => {
        if (queryError) {
            console.error(queryError);
            captureException(queryError?.graphQLErrors[0], { curFilter, nextKey });
        }
    }, [curFilter, nextKey, queryError]);


    const selectedProduct = curQueryParams.product as ActiveALSProduct;
    const isProductLocked = selectedProduct && !productsIndex[selectedProduct].enabled;
    const showCompanyPanel = user?.companies && user.companies.length > 1;

    return (
        <Fragment>
            <ProductFilter
                curFilter={curQueryParams}
                productList={productList}
                loading={isProductsLoading}
            />
            <Box
                display="flex"
                flexDirection="column"
                flexWrap="nowrap"
                height="100%"
            >
                {!isProductLocked && (
                    <Box className={styles.filters}  display="flex" flexDirection="row" alignItems="start">
                        <Box flexGrow="1">
                            <Filters
                                navigate={navigate}
                                curFilter={curQueryParams}
                                filterRef={filterRef}
                            />
                        </Box>
                        <Box className={styles.exportCsvButton}>
                            <ExportCsvButton filter={filterRef} />
                        </Box>
                    </Box>
                )}
                <Box className={styles.contentContainer}>
                    {showCompanyPanel && (
                        <CompanyPanel
                            collection={user.companies}
                            placement={"inline"}
                            className={styles.companyPanel}
                            title={(item) => item?.name || "!!UNKNOWN COMPANY!!"}
                            subtitle={(item) => {
                                const productType = curFilter.product || "GLOBAL";
                                const leadCount = productsIndex[productType].count;
                                return !isNil(leadCount)
                                    ? `${leadCount} Leads`
                                    : "";
                            }}
                            selected={(item) => item?.id === selectedCompany?.id}
                            onSelect={(item) => item?.id && changeCompany(item?.id)}
                        />
                    )}
                    {!isProductLocked ? (
                        <LeadsList
                            leads={leads}
                            nextKey={nextKey}
                            page={page}
                            selectedProduct={selectedProduct}
                            loading={isLeadsLoading}
                            queryError={queryError}
                            navigate={navigate}
                        />
                    ) : (
                        <Box className={styles.secondaryContent}>
                            <ProductCta topic={selectedProduct} />
                        </Box>
                    )}
                </Box>
            </Box>
        </Fragment>
    );
};

export default createPrivateView(Leads, {
    title: "Leads",
    internalScroll: true,
    internalPad: true,
});