import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";

import type { LeadProductsDetails } from "~/model/leads";
import lock from "~/assets/images/lock.png";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between",
    },
    title: {},
    count: {
        display: "flex",
        flexFlow: "column",
    },
    spinnerContainer: {
        position: "relative",
        width: "1em",
        height: "1em",
    },
    spinner: {
        color: theme.palette.text.contrast,
    },
}));

interface IProductFilterContentProps {
    title: string;
    count?: number;
    product?: LeadProductsDetails;
    loading?: boolean;
}

export const ProductFilterContent: React.FC<IProductFilterContentProps> = ({
    title,
    count,
    product,
    loading,
}) => {
    const styles = useStyles();
    const showLock = product && !product.enabled;

    return (
        <Box className={styles.root}>
            <Box className={styles.title}>
                {title}
            </Box>
            <Box
                className={styles.count}
                style={{ opacity: showLock ? 1 : 0.6 }}
            >
                {showLock ? (
                    <img
                        src={lock}
                        style={{
                            height: "1.15em",
                            width: "1.15em",
                        }}
                    />
                ) : (loading ? (
                    <Box className={styles.spinnerContainer}>
                        <CircularProgress className={styles.spinner} size={"1em"} />
                    </Box>
                ) : (
                    count
                ))}
            </Box>
        </Box>
    );
};